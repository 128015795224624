import React from "react";

const CallToActionSeven = () => {
  return (
    <div className="fancy-short-banner-fourteen mt-150 md-mt-80">
      <div className="container">
        <div className="row">
          <div className="col-12" data-aos="fade-up" data-aos-duration="1200">
            <div className="title-style-thirteen">
              <h2>
                66+ khách hàng <br />
                đã sử dụng dịch vụ eKYC
              </h2>
            </div>
            <p className="lh-lg">Chúng tôi luôn đồng hành cùng Tổ chức, doanh nghiệp trong hành trình ứng dụng công nghệ tiên tiến, chất lượng nhất, đảm bảo an toàn bảo mật và mang đến những trải nghiệm tuyệt vời cho các khách hàng của bạn.</p>
            <a href="/bang-gia" className="theme-btn-twelve">
              Xem bảng giá
            </a>
          </div>
        </div>
      </div>
      {/* <!-- /.container --> */}
      <img
        src="images/shape/239.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/240.svg"
        alt="shape"
        className="shapes shape-two"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-three"
      />
      <img
        src="images/shape/233.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/234.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/235.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/236.svg"
        alt="shape"
        className="shapes shape-seven"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-eight"
      />
    </div>
  );
};

export default CallToActionSeven;
