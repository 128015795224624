import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/Header";
import Social from "../../../components/social/Social";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import AboutFour from "../../../components/about/AboutFour";
import CallToActionThree from "../../../components/call-to-action/CallToActionThree";

const ProductCustomerSupport = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          eKYC | Tính năng
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading font-gilroy-bold">Xác thực căn cước công dân gắn chip chính xác <b style={{color: '#0ea5e9'}}>100%</b></h1>
                <p className="sub-heading font-gilroy-bold">
                Với cơ sở dữ liệu <span style={{color: '#0ea5e9'}}>RAR-C06</span> của Bộ Công an
                </p>
                <a
                  href="/demo/ekyc"
                  className="font-gilroy-bold theme-btn-six trial-button mt-50 md-mt-30"
                  style={{background: '#0ea5e9'}}
                >
                  {" "}
                  Trải nghiệm ngay
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-two text-center mb-150 md-mb-70">
            <p>eKYC</p>
            <h2 className=" font-gilroy-bold">
            Xác minh danh tính điện tử
              <span style={{color:'#0ea5e9'}}>
              {" "}nhanh chóng{" "}
                <img src="images/shape/line-shape-8.svg" alt="line shape" />
              </span>{" "}
              <span  style={{color:'#6f55ff'}}>chính xác</span>
            </h2>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_04.svg" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Đặc điểm nổi bật</h6>
                    <h3 className="title font-gilroy-bold">
                    Xác thực chính xác 100%
                    </h3>
                    <p className="font-rubik">
                    Kết quả xác thực định danh điện tử chính xác 100% do dữ liệu trên thẻ căn cước công dân gắn chip được xác minh cơ sở dữ liệu của Bộ Công An
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_05.svg" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Đáp ứng</h6>
                    <h3 className="title font-gilroy-bold">
                    Thời gian thực hiện 1-2 phút
                    </h3>
                    <p className="font-rubik">
                    Chỉ mất từ 1-2 phút để thực hiện toàn trình (on-boarding) một luồng xác thực eKYC và xác thực thông tin thẻ chip với RAR-C06
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_06.svg" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Xác thực</h6>
                    <h3 className="title font-gilroy-bold">
                    Chống giả mạo
                    </h3>
                    <p className="font-rubik">
                      Xác thực chữ ký với hệ thống CSDLQG về dân cư và kiểm tra tính toàn vẹn dữ liệu
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_07.svg" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Trang quản lý dành cho khách hàng</h6>
                    <h3 className="title font-gilroy-bold">
                      Quản lý giao dịch, xem chi tiết giao dịch
                    </h3>
                    <p className="font-rubik">
                    Cung cấp cho đơn vị tích hợp công cụ mạnh để thống kê và giám sát
các lỗi theo khung thời gian được chọn, giúp nhanh chóng phát hiện
các bất thường và có biện pháp kịp thời ngăn chặn và xử lý.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
				Fancy Text Block Thirteen
			===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h2 className="font-gilroy-bold">Xác thực khuôn mặt qua
video và giọng nói</h2>
                </div>
                {/* /.title-style-two */}
                <p>
                Phát hiện các trường hợp cố tình giả mạo khuôn mặt, đặc biệt các
trường hợp sử dụng khuôn mặt giả (ảnh, video chụp từ thiết bị, mặt
nạ...) thông qua video call, video xác thực khuôn mặt và xác thực
chuỗi số ngẫu nhiên bằng giọng nói.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src="images/assets/feature-img-11.png" alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

      {/*=====================================================
				Useable Tools
			===================================================== */}
      <div className="useable-tools-section-two bg-shape mb-250 mt-150 md-mt-100 md-mb-100">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="container">
            <div className="title-style-two text-center mb-70 md-mb-10">
              <div className="row">
                <div className="col-lg-10 col-md-11 m-auto">
                  <p>Công cụ tích hợp</p>
                  <h2 className="font-gilroy-bold">
                    Hỗ trợ SDK cho các nền tảng Androi, IOS, React Native, Flutter, Web
                  </h2>
                </div>
              </div>
            </div>
            {/*  /.title-style-two */}

            <div className="icon-wrapper">
              <Social />
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.useable-tools-section-two */}

      {/* 	=====================================================
				Fancy Text Block Fourteen
			===================================================== */}
      <div className="fancy-text-block-fourteen pt-30">
        <div className="container">
          <AboutFour />
        </div>
      </div>
      {/* /.fancy-text-block-fourteen */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-225 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProductCustomerSupport;
