import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import CallToActionFour from "../../../../components/call-to-action/CallToActionFour";
import FooterSeven from "../../../../components/footer/FooterSeven";
import CopyRightThree from "../../../../components/footer/CopyRightThree";
import FaqClassic from "../../../../components/faq/FaqClassicTwo";
import CounterFour from "../../../../components/counter/CounterFour";
import Pricing from "../../../../components/pricing/pricing-two/Pricing";

const ProjectManagement = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          {" "}
          eKYC | bảng giá
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
				Pricing Section One
			============================================== */}
      <div className="pricing-section-one mb-150 md-mb-80">
        <div className="fancy-hero-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 m-auto">
                <h2 className="font-rubik">Hình thức cung cấp dịch vụ</h2>
              </div>
              <div className="col-12 m-auto">
                <p className="font-rubik">
                Thiết bị đọc thẻ chip - SDK - API
                </p>
              </div>
            </div>
          </div>
          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
        </div>
        {/* /.fancy-hero-one */}
        <div className="pricing-table-area">
          <img
            src="images/shape/62.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/63.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <div className="container">
            <div className="tab-content">
              <Pricing />
            </div>
          </div>
          {/* End .container */}
        </div>
      </div>
      {/* /.pricing-section-one */}

      {/* =====================================================
				Counter Info Standard
			===================================================== */}
      <div className="counter-info pb-150 md-pb-80">
        <div className="container">
          <CounterFour />
        </div>
      </div>
      {/* /.counter-info */}

      {/* 	=============================================
				Fancy Text Block Fifteen
			============================================== */}
      <div className="fancy-text-block-fifteen">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="bg-wrapper">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper md-pb-40">
                  <img src="images/icon/42.svg" alt="iocn" className="icon" />
                  <div className="more-text">
                    <span>NEO nKYC</span>
                  </div>
                  <p className="font-rubik">
                  Chúng tôi luôn đặt trí lực, tâm lực vào mỗi sản phẩm bởi chất lượng là cốt lõi, là sự hài lòng của khách hàng và là thành công của chúng tôi.
                  </p>
                  <h6>
                  Nguyễn Văn Đào <span>CEO</span>
                  </h6>
                </div>
                {/*  /.text-wrapper */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 order-lg-first"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img
                  src="images/media/img_40.png"
                  alt="media"
                  className="main-img"
                />
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* /.bg-wrapper */}

          <div className="contact-banner mt-100 md-mt-60">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-xl-7 col-lg-8"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <p className="font-rubik">
                Bạn muốn có biết về chi phí sử dụng eKYC? Liên hệ với chúng tôi
                </p>
              </div>
              <div
                className="col-xl-4 col-lg-3"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <Link to="/contact-cs" className="theme-btn-four ms-auto">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
          {/* /.contact-banner */}
        </div>
      </div>
      {/*  /.fancy-text-block-fifteen */}

      {/* 
      =============================================
				Faq Classic
		  ============================================== */}
      <div className="faq-classic pt-150 pb-130 md-pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-one">
                <h6 className="font-rubik">Câu hỏi thường gặp</h6>
                <h2 className="font-gilroy-bold">Nếu muốn biết thêm, xem nhanh các câu hỏi</h2>
              </div>
              {/* /.title-style-one */}
              <Link to="/kien-thuc" className="theme-btn-one mt-50 md-mt-30">
                Hoặc xem các bài viết
              </Link>
            </div>
            {/* End .col */}
            <div className="col-lg-6">
              <div className="md-mt-60">
                <FaqClassic />
              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.faq-classic */}

      {/* =====================================================
			  Call To Action  
			===================================================== */}
      <div className="fancy-short-banner-one space-fix">
        <div className="container">
          <CallToActionFour />
        </div>
        {/*  /.container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/*  /.fancy-short-banner-one */}

      {/* 
      =============================================
				Footer
		  ============================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProjectManagement;
