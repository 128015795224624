import React from "react";
import { Helmet } from "react-helmet";
import BillingDetails from "../../../components/e-commerce/checkout/BillingDetails";
import LogIn from "../../../components/e-commerce/checkout/LogIn";
import Payment from "../../../components/e-commerce/checkout/Payment";
import PromoCode from "../../../components/e-commerce/checkout/PromoCode";
import Header from "../../../components/header/HeaderDemo";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import axios from "axios"
import { toast } from 'react-toastify';
import Select from 'react-select';

const FakeDetection = () => {

  const options = [
    { value: 'liveness', label: 'Xác định ảnh giả mạo' },
    
  ]

  const labels = {
    idnumber: "Số CMND/CCCD/Hộ chiếu",
    name: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    card_type: "Loại giấy tờ",
    issue_date: "Ngày cấp",
    issued_place: "Nơi cấp",
    address: "Địa chỉ",
    expired_date: "Ngày hết hạn",
    nationality: "Quốc tịch",
    place_of_origin: "Nguyên quán",
    face_match: "Ảnh chân dung khớp?",
    face_score: "Độ chính xác ảnh chân dung",
    class: "Thật/Giả?",
    score: "Độ chính xác xác định thật giả",
  }

  const labels_2 = {
    class: "Thật/Giả?",
    score: "Độ chính xác xác định thật giả",
  }

  const labels_1 = {
    idnumber: "Số CMND/CCCD/Hộ chiếu",
    name: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    card_type: "Loại giấy tờ",
    issue_date: "Ngày cấp",
    issued_place: "Nơi cấp",
    address: "Địa chỉ",
    expired_date: "Ngày hết hạn",
    nationality: "Quốc tịch",
    place_of_origin: "Nguyên quán"
  }

  const [serivce, setService] = React.useState(options[0])
  const [frontImg, setFrontImg] = React.useState(null)
  const [backImg, setBackImg] = React.useState(null)
  const [faceImg, setFaceImg] = React.useState(null)

  const frontRef = React.createRef()
  const backRef = React.createRef()
  const faceRef = React.createRef()

  const [result, setResult] = React.useState(null)
  const [text, setText] = React.useState('Bóc tách thông tin')

  /*Hàm nhận file và chuyển thành base64*/
  const getBase64 = (file,file_name) => {
    return new Promise((resolve,reject) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
        if (file_name=='front') setFrontImg(baseURL)
        if (file_name=='back') setBackImg(baseURL)
        if (file_name=='face') setFaceImg(baseURL)
      };
    });
  }

  const actionEKYC = async () => {
    setResult({})
    /*Kiểm tra dữ liệu front, back, face theo từng service, phải not null*/
    if (serivce.value=='front' && frontImg==null) {
      alert('Chưa chọn ảnh mặt trước CMND/CCCD')
      return
    }
    if (serivce.value=='back' && backImg==null) {
      alert('Chưa chọn ảnh mặt sau CMND/CCCD')
      return
    }
    if (serivce.value=='ekyc' && (frontImg==null || backImg==null || faceImg==null)) {
      alert('Chưa chọn đủ ảnh mặt trước, mặt sau và ảnh chân dung CMND/CCCD')
      return
    }
    if (serivce.value=='ocr' && (frontImg==null || backImg==null)) {
      alert('Chưa chọn đủ ảnh mặt trước và mặt sau CMND/CCCD')
      return
    }
    /*Trường hợp front, bỏ data:image/*;base64*/
    setText('Đang thực hiện...')
    if (serivce.value=='front') {
      var frontImg_ = frontImg.split(',')[1]
      /*Gửi lên server*/
      axios.post('https://ocr-api.nkyc.vn/api/ekyc/demo/v1/front',{
        image_front_base64: frontImg_
      }).then(res=>{
        console.log(res)
        if (res.data.code==200){
          toast.info('Bóc tác dữ liệu thành công', {
            position: 'bottom-left',
          })
        }else{
          toast.info(res.data.message, {
            position: 'bottom-left',
          })
        }
        setText('Bóc tách thông tin')
        setResult(res.data.result)
      }).catch(err=>{
        console.log(err)
        setText('Bóc tách thông tin')
        toast.info('Bóc tác dữ liệu thất bại, vui lòng thử lại', {
          position: 'bottom-left',
        })
      })
    }
    /*Trường hợp back, bỏ data:image/*;base64*/
    if (serivce.value=='back') {
      var backImg_ = backImg.split(',')[1]
      /*Gửi lên server*/
      axios.post('https://ocr-api.nkyc.vn/api/ekyc/demo/v1/back',{
        image_back_base64: backImg_
      }).then(res=>{
        console.log(res)
        if (res.data.code==200){
          toast.info('Bóc tác dữ liệu thành công', {
            position: 'bottom-left',
          })
        }else{
          toast.info(res.data.message, {
            position: 'bottom-left',
          })
        }
        setText('Bóc tách thông tin')
        setResult(res.data.result)
      }).catch(err=>{
        console.log(err)
        setText('Bóc tách thông tin')
        alert('Bóc tác dữ liệu thất bại, vui lòng thử lại')
      })
    }
    if (serivce.value=='liveness') {
      var faceImg_ = faceImg.split(',')[1]
      /*Gửi lên server*/
      axios.post('https://ocr-api.nkyc.vn/api/ekyc/demo/v1/liveness',{
        image_face_base64: faceImg_
      }).then(res=>{
        console.log(res)
        if (res.data.code==200){
          toast.info('Kiểm tra thành công', {
            position: 'bottom-left',
          })
        }else{
          toast.info(res.data.message, {
            position: 'bottom-left',
          })
        }
        setText('Bóc tách thông tin')
        setResult(res.data.result)
      }).catch(err=>{
        console.log(err)
        setText('Bóc tách thông tin')
        toast.info('Bóc tác dữ liệu thất bại, vui lòng thử lại', {
          position: 'bottom-left',
        })
      })
    }
    /*Trường hợp ekyc, bỏ data:image/*;base64*/
    if (serivce.value=='ekyc') {
      var frontImg_ = frontImg.split(',')[1]
      var backImg_ = backImg.split(',')[1]
      var faceImg_ = faceImg.split(',')[1]
      /*Gửi lên server*/
      axios.post('https://ocr-api.nkyc.vn/api/ekyc/demo/v1/ekyc',{
        image_front_base64: frontImg_,
        image_back_base64: backImg_,
        image_face_base64: faceImg_
      }).then(res=>{
        console.log(res)
        if (res.data.code==200){
          toast.info('Bóc tác dữ liệu thành công', {
            position: 'bottom-left',
          })
        }else{
          toast.info(res.data.message, {
            position: 'bottom-left',
          })
        }
        setText('Bóc tách thông tin')
        setResult(res.data.result)
      }).catch(err=>{
        console.log(err)
        setText('Bóc tách thông tin')
        toast.info('Bóc tác dữ liệu thất bại, vui lòng thử lại', {
          position: 'bottom-left',
        })
      })
    }
    /*Trường hợp ocr, bỏ data:image/*;base64*/
    if (serivce.value=='ocr') {
      var frontImg_ = frontImg.split(',')[1]
      var backImg_ = backImg.split(',')[1]
      /*Gửi lên server*/
      axios.post('https://ocr-api.nkyc.vn/api/ekyc/demo/v1/ocr',{
        image_front_base64: frontImg_,
        image_back_base64: backImg_
      }).then(res=>{
        console.log(res)
        if (res.data.code==200){
          toast.info('Bóc tác dữ liệu thành công', {
            position: 'bottom-left',
          })
        }else{
          toast.info(res.data.message, {
            position: 'bottom-left',
          })
        }
        setText('Bóc tách thông tin')
        setResult(res.data.result)
      }).catch(err=>{
        console.log(err)
        setText('Bóc tách thông tin')
        toast.info('Bóc tác dữ liệu thất bại, vui lòng thử lại', {
          position: 'bottom-left',
        })
      })
    }
  }

  return (
    
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>eKYC | Định danh khách hàng trực tuyến</title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* <!-- 
    =============================================
    Theme Main Menu
    ============================================== 
    --> */}
      <Header />

      {/* <!-- 
    =============================================
    Checkout Page
    ============================================== 
    --> */}

      <div className="checkout-section pt-100 pb-100 lg-pt-180 sm-pb-50">
        <div className="row pt-50 pb-50 mb-50 mt-20" style={{background:'#fff7ef'}}>
            <div className="col-xl-12 col-lg-12">
              <h2 className="heading text-center">Xác định ảnh giả mạo</h2>
              <p className="text-center mt-2">Phát hiện chỉnh sửa, ảnh photo, ảnh chụp lại</p>
            </div>
					</div>
        <div className="container">
          
          {/* <!-- /.checkout-toggle-area --> */}
          
          <div className="checkout-form">
            <div className="row">
              <div className="col-xl-4 border rounded col-lg-4">
                <h5 className="py-4">Chọn dịch vụ</h5>
                <form>
                  <div className="tp-mail">
                    <Select 
                          options={options} defaultValue={serivce} onChange={(e)=>{
                          setService(e)
                          console.log(e)
                          setResult(null)
                        }}/>
                  </div>
                  <div className="row">
                    {(serivce.value=='front' || serivce.value=='ocr' || serivce.value=='ekyc') && <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Ảnh mặt trước</label><br></br>
                        <input
                          type="file"
                          name="front"
                          onChange={(e)=>getBase64(e.target.files[0],'front')}
                          ref={frontRef}
                          placeholder="Ảnh mặt trước"
                          required
                        />
                      </div>
                    </div>}
                    {(serivce.value=='back' || serivce.value=='ocr' || serivce.value=='ekyc') && <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Ảnh mặt sau</label><br></br>
                        <input
                          type="file"
                          name="back"
                          onChange={(e)=>getBase64(e.target.files[0],'back')}
                          ref={backRef}
                          placeholder="Ảnh mặt trước"
                          required
                        />
                      </div>
                    </div>}
                    {(serivce.value=='ekyc' || serivce.value=='liveness') && <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Ảnh chân dung</label><br></br>
                        <input
                          type="file"
                          name="face"
                          onChange={(e)=>getBase64(e.target.files[0],'face')}
                          ref={faceRef}
                          placeholder="Ảnh mặt trước"
                          required
                        />
                      </div>
                    </div>}
                  </div>
                  <div className="tp-login-button mt-50">
                    <button onClick={actionEKYC} className="bg-black text-white w-100 mb-20 rounded py-2 font-weight-bold" type="button">{text}</button>
                  </div>
                  
                </form>
              </div>
              {/* <!-- /.col- --> */}

              <div className="col-xxl-8 col-lg-8 ms-auto">
                {result && <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 ekyc">
                  <div 
                      className="project-start-content"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="400"
                    >
                      <div className="col-lg-12 col-md-12 card p-4">
                        <div className="row">
                          
                          <div className="col-lg-8 col-md-12 col-sm-12">
                            <h5 className="sub-title">Kết quả bóc tách</h5>
                            <div className="row">
                              {serivce.value=='liveness' && result && labels_2 && Object.keys(labels_2).map((key,index)=>{
                                return <div key={'liveness'} className="col-lg-12 col-md-12 col-sm-12">
                                  <label>{labels_2[key]}</label>
                                  <input type="text" value={result[key]} className="form-control form-control-sm mb-2" disabled/>
                                </div>
                              })}
                              {(serivce.value=='back' || serivce.value=='front' || serivce.value=='ocr') && result && labels_1 && Object.keys(labels_1).map((key,index)=>{
                                return <div key={'back'} className="col-lg-12 col-md-12 col-sm-12">
                                  <label>{labels_1[key]}</label>
                                  <input type="text" value={result[key]} className="form-control form-control-sm mb-2" disabled/>
                                </div>
                              })}
                              {serivce.value=='ekyc' && result && labels && Object.keys(labels).map((key,index)=>{
                                return <div key={'ekyc'} className="col-lg-12 col-md-12 col-sm-12">
                                  <label>{labels[key]}</label>
                                  <input type="text" value={key=='face_match'?(result[key]=="1"?"Trùng khớp":"Không khớp"):result[key]} className="form-control form-control-sm mb-2" disabled/>
                                </div>
                              })}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <h5 className="sub-title">Các ảnh giấy tờ</h5>
                            {frontImg && 
                            <><label>Ảnh mặt trước</label>
                            <div className="single-gallery-item">
                              <div className="popup-btn">
                                <img src={frontImg} className='w-100 rounded' alt="Gallery" />
                              </div>
                            </div></>
                            }
                            {backImg && <>
                            <label>Ảnh mặt sau</label>
                            <div className="single-gallery-item">
                              <div className="popup-btn">
                                <img src={backImg} className='w-100' alt="Gallery" />
                              </div>
                            </div></>}
                            {faceImg && <>
                            <label>Ảnh chân dung</label>
                            <div className="single-gallery-item">
                              <div className="popup-btn">
                                <img src={faceImg} className='w-100' alt="Gallery" />
                              </div>
                            </div></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>}
              </div>
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.checkout-form --> */}
        </div>
        {/* <!-- /.container --> */}
      </div>

      {/* <!-- /.checkou-section --> */}
    </div>
  );
};

export default FakeDetection;
